<template>
	<div>
		<a-select v-model="pdfOrientation" style="width: 120px" class="text-primary" :showArrow="true">
			<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
			<a-select-option value="portrait" class="text-primary">
				Portrait
			</a-select-option>
			<a-select-option value="landscape" class="text-primary">
				Landscape
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
export default {
	computed: {
		pdfOrientation: {
			get() {
				return this.$store.state.pdfOrientation
			},
			set(value) {
				this.$store.commit('SET_PDF_ORIENTATION', value)
			}
		},
	}
}
</script>
